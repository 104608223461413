import { AgentSkill } from 'store/base_user_info'
import { Carrier, CarrierInfo, LOI } from 'store/carriers'
import { CarrierAppointment, CarrierStatus } from 'store/carrier_appointments'
import { CarrierAppointmentRequestStatus } from 'store/carrier_appointment_requests'
import store from 'store'

import {
  CardType,
  SmartflowHealthCarriers,
  SmartflowLifeCarriers,
  SmartflowMedicareCarriers
} from '../constants'

export interface CardInfo {
  type: CardType
  data?: {
    hidden?: boolean
    disabled?: boolean
    information?: string
  }
}

const isSmartflowCarrier = (carrierInfo: CarrierInfo) => {
  if (carrierInfo.loi === LOI.Medicare) {
    return SmartflowMedicareCarriers.includes(carrierInfo.name)
  } else if (carrierInfo.loi === LOI.Health) {
    return SmartflowHealthCarriers.includes(carrierInfo.name)
  } else if (carrierInfo.loi === LOI.Life) {
    return SmartflowLifeCarriers.includes(carrierInfo.name)
  }
  return false
}

export const getCarrierAppointmentStatus = (carrierAppointment: CarrierAppointment) => {
  if (!carrierAppointment.statusFullySynced) {
    return CarrierStatus.NotAppointed
  }

  switch (carrierAppointment.status) {
    case CarrierStatus.Appointed:
      return CarrierStatus.Appointed
    case CarrierStatus.Terminated:
      return CarrierStatus.Terminated
    case CarrierStatus.NotAppointed: {
      if (carrierAppointment.justInTimeStateDetails.find(row => row.status === 'ACTIVE')) {
        return CarrierStatus.Appointed
      }
      return CarrierStatus.NotAppointed
    }
    default:
      break
  }

  return CarrierStatus.NotAppointed
}

const getAppointmentStatus = (carrier: Carrier, loi: string, planYear: number) => {
  const carrierAppointments = store.getState().carrierAppointments.items

  const carrierInfoForPlanYear = carrierAppointments.find(
    row => row.carrier.name === carrier && row.carrier.loi === loi && row.planYear === planYear
  )

  if (!carrierInfoForPlanYear) {
    return CarrierStatus.NotAppointed
  }

  return getCarrierAppointmentStatus(carrierInfoForPlanYear)
}

const appointmentRequestForPlanYear = (carrierName: Carrier, loi: string, planYear: number) => {
  const appointmentRequests = store.getState().carrierAppointmentRequests.items
  return appointmentRequests.find(
    row => row.carrier.name === carrierName && row.carrier.loi === loi && row.planYear === planYear
  )
}

const appointmentRequestStatusForPlanYear = (
  carrierName: Carrier,
  loi: string,
  planYear: number
) => {
  return appointmentRequestForPlanYear(carrierName, loi, planYear)?.status
}

const unavailable_carrier = (skills: AgentSkill[], carrierName: string) => {
  if (carrierName == null) {
    return true
  }
  if (!skills) {
    skills = []
  }
  switch (carrierName) {
    case Carrier.Protective:
      return !skills.find(skill => skill.name === 'sell_life_AU_Term_insurance' && skill.isEnabled)
    default:
      return false
  }
}

const getCardTypeForPlanYear = (carrierInfo: CarrierInfo, planYear: number): CardInfo => {
  const userInfo = store.getState().userInfo
  const carrierAppointments = store.getState().carrierAppointments.items

  if (
    (isSmartflowCarrier(carrierInfo) && !userInfo.isNiprPulled) ||
    unavailable_carrier(userInfo.skills, carrierInfo.name)
  ) {
    return { type: CardType.Unavailable }
  }

  const appointmentStatus = getAppointmentStatus(carrierInfo.name, carrierInfo.loi, planYear)

  switch (appointmentStatus) {
    case CarrierStatus.Appointed:
      return { type: CardType.ViewDetails }
    case CarrierStatus.Terminated:
      return { type: CardType.Terminated }
  }

  if (
    isSmartflowCarrier(carrierInfo) &&
    appointmentRequestStatusForPlanYear(carrierInfo.name, carrierInfo.loi, planYear) != null
  ) {
    return { type: CardType.Progress }
  }

  if (isSmartflowCarrier(carrierInfo)) {
    return { type: CardType.Eligibility }
  }

  if (carrierInfo.loi === LOI.Health) {
    return {
      type: CardType.Instructions
    }
  }

  return { type: CardType.Instructions }
}

const shouldDisableCarrier = (carrierInfo: CarrierInfo) => {
  const userInfo = store.getState().userInfo
  // Add the carriers that required to be hidden
  // Carriers from Health LOI
  const disabledHealthCarriers = [Carrier.HII]
  // Carriers from Medicare LOI
  const disabledMedicareCarriers = [Carrier.SureBridge]

  const disabledLifeCarriers: Carrier[] = []

  // Following is an exmaple to hide a specific carrier card in non-testing environments
  // !['development', 'integration'].includes(DD_ENV) && disabledLifeCarriers.push(Carrier.Lumico)

  let testUserNPNs = new Map<Carrier, string[]>()
  // Following is an example to add test users for a Carrier.
  // Other users will not see the carrier card except these.
  // This should be used temporarily for testing only.
  // testUserNPNs.set(Carrier.MutualOfOmaha, ['19603758', '18110344'])

  switch (carrierInfo.loi) {
    case LOI.Health:
      if (disabledHealthCarriers.includes(carrierInfo.name)) {
        return true
      }
      return carrierInfo.name === Carrier.IHC
    case LOI.Medicare: {
      if (disabledMedicareCarriers.includes(carrierInfo.name)) {
        return true
      }
      if (carrierInfo.name === Carrier.Cigna) {
        return ['Teleperformance', 'TTEC', 'DialogDirect', 'Dominion', 'Dominion '].includes(
          userInfo.callCenter
        )
      }

      switch (userInfo.callCenter) {
        case 'All Web Leads':
          return [Carrier.MutualOfOmaha, Carrier.SureBridge, Carrier.AetnaMedSupp].includes(
            carrierInfo.name
          )
      }

      break
    }
    case LOI.Life:
      if (disabledLifeCarriers.includes(carrierInfo.name)) return true
      else
        return (
          testUserNPNs.has(carrierInfo.name) &&
          !testUserNPNs.get(carrierInfo.name)?.includes(userInfo.npn)
        )
  }

  return false
}

const getCardType = (carrierInfo: CarrierInfo, planYear: number): CardInfo => {
  const userInfo = store.getState().userInfo
  if (isSmartflowCarrier(carrierInfo) && !userInfo.isNiprPulled) {
    return { type: CardType.Unavailable }
  }

  if (shouldDisableCarrier(carrierInfo)) {
    return { type: CardType.Unavailable, data: { hidden: true } }
  }

  const cardTypeMapper = {
    MEDICARE: {
      currentYear: () => {
        const cardInfo = getCardTypeForPlanYear(carrierInfo, planYear)
        if (cardInfo.type === CardType.Eligibility && new Date().getUTCMonth() > 5) {
          return { type: CardType.Closed }
        }
        return cardInfo
      },
      nextYear: () => {
        const beforeJune = new Date().getUTCMonth() < 5
        if (beforeJune) {
          return { type: CardType.ComingSoon }
        }

        if (
          getAppointmentStatus(carrierInfo.name, carrierInfo.loi, planYear - 1) ===
            CarrierStatus.Appointed &&
          getAppointmentStatus(carrierInfo.name, carrierInfo.loi, planYear) ===
            CarrierStatus.NotAppointed
        ) {
          return { type: CardType.ReCertification }
        }

        const cardType = getCardTypeForPlanYear(carrierInfo, planYear)

        if (![CardType.Eligibility, CardType.Progress].includes(cardType.type)) {
          return cardType
        }

        if (
          appointmentRequestStatusForPlanYear(carrierInfo.name, carrierInfo.loi, planYear - 1) !==
          CarrierAppointmentRequestStatus.InProgress
        ) {
          return cardType
        }

        return {
          type: CardType.Eligibility,
          data: { disabled: true, information: 'Previous Year In Progress' }
        }
      }
    },
    HEALTH: {
      currentYear: () => {
        return getCardTypeForPlanYear(carrierInfo, planYear)
      },
      nextYear: () => {
        return { type: CardType.ComingSoon }
      }
    },
    LIFE: {
      currentYear: () => {
        const cardInfo = getCardTypeForPlanYear(carrierInfo, planYear)
        if (carrierInfo.name !== Carrier.MutualOfOmaha) {
          return cardInfo
        }
        if (cardInfo.type !== CardType.ViewDetails) {
          return cardInfo
        }
        const request = appointmentRequestForPlanYear(carrierInfo.name, carrierInfo.loi, planYear)
        if (!request) {
          // appointed without starting workflow
          return cardInfo
        }
        if (request.status !== CarrierAppointmentRequestStatus.Completed) {
          // appointment still in progress
          return cardInfo
        }
        const workflowStatus = store
          .getState()
          .appointmentRequestsWorkflowStatus.items.find(
            row => row.appointmentRequestId === request.id
          )
        if (!workflowStatus) {
          // unusual case
          return cardInfo
        }
        if (workflowStatus.pageData?.userInput?.accountCreated) {
          return cardInfo
        }
        return {
          type: CardType.Progress
        }
      },
      nextYear: () => {
        return { type: CardType.ComingSoon }
      }
    }
  }

  const currentYear = new Date().getUTCFullYear()

  if (![currentYear, currentYear + 1].includes(planYear)) {
    return getCardTypeForPlanYear(carrierInfo, planYear)
  }

  const key = planYear === currentYear ? 'currentYear' : 'nextYear'

  if (!cardTypeMapper[carrierInfo.loi]) {
    throw carrierInfo.loi + ' unsupported carrier loi ' + JSON.stringify(carrierInfo)
  }
  return cardTypeMapper[carrierInfo.loi][key]()
}

export { getCardType, getCardTypeForPlanYear, getAppointmentStatus }
