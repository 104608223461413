import { Carrier, LOI } from 'store/carriers'

import { PlateLabelMap as AetnaPlateLabelMap } from '../aetna/plate_map'
import { PlateLabelMap as AmbetterHealthPlateLabelMap } from '../health/ambetter/plate_map'
import { PlateLabelMap as AnthemHealthPlateLabelMap } from '../health/anthem/plate_map'
import { PlateLabelMap as AnthemPlateLabelMap } from '../anthem/plate_map'
import { PlateLabelMap as CignaPlateLabelMap } from '../health/cigna/plate_map'
import { PlateLabelMap as HCSCHealthPlateLabelMap } from '../health/hcsc/plate_map'
import { PlateLabelMap as HumanaPlateLabelMap } from '../humana/plate_map'
import { PlateLabelMap as LGALifePlateLabelMap } from '../life/lga/plate_map'
import { PlateLabelMap as LumicoLifePlateLabelMap } from '../life/lumico/plate_map'
import { PlateLabelMap as MolinaHealthPlateLabelMap } from '../health/molina/plate_map'
import { PlateLabelMap as OmahaLifePlateLabelMap } from '../life/omaha/plate_map'
import { PlateLabelMap as OscarPlateLabelMap } from '../health/oscar/plate_map'
import { PlateLabelMap as PrudentialLifePlateLabelMap } from '../life/prudential/plate_map'
import { PlateLabelMap as UHCACAPlateLabelMap } from '../health/uhc_aca/plate_map'
import { PlateLabelMap as UnitedHealthCarePlateLabelMap } from '../united_health_care/plate_map'
import { PlateLabelMap as WellCarePlateLabelMap } from '../wellcare/plate_map'

const PlateLabelMap = {
  [LOI.Medicare]: {
    [Carrier.Aetna]: AetnaPlateLabelMap,
    [Carrier.Humana]: HumanaPlateLabelMap,
    [Carrier.Anthem]: AnthemPlateLabelMap,
    [Carrier.UnitedHealthCare]: UnitedHealthCarePlateLabelMap,
    [Carrier.WellCare]: WellCarePlateLabelMap
  },
  [LOI.Health]: {
    [Carrier.Anthem]: AnthemHealthPlateLabelMap,
    [Carrier.Ambetter]: AmbetterHealthPlateLabelMap,
    [Carrier.HCSC]: HCSCHealthPlateLabelMap,
    [Carrier.Cigna]: CignaPlateLabelMap,
    [Carrier.UnitedHealthCareACA]: UHCACAPlateLabelMap,
    [Carrier.Oscar]: OscarPlateLabelMap,
    [Carrier.Molina]: MolinaHealthPlateLabelMap
  },
  [LOI.Life]: {
    [Carrier.MutualOfOmaha]: OmahaLifePlateLabelMap,
    [Carrier.LGA]: LGALifePlateLabelMap,
    [Carrier.Prudential]: PrudentialLifePlateLabelMap,
    [Carrier.Lumico]: LumicoLifePlateLabelMap
  }
}

export const getPlateLabel = (carrierName: string, loi: LOI, progress: string) => {
  return PlateLabelMap[loi][carrierName]?.[progress] || progress
}
