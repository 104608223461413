import React from 'react'
import { useForm } from 'react-hook-form'

import { Grid } from '@material-ui/core'

import Button from 'components/button'
import Note from 'components/box/note'
import { PlateProps } from 'screens/carrier_appointments/commons/plate'
import TimeToComplete from 'screens/carrier_appointments/commons/time_to_complete'

interface Props extends PlateProps {
  className?: string
  style?: React.CSSProperties
}

const NotAppointed = (props: Props) => {
  const { doNext } = props

  const form = useForm<{
    canLogInToPortal: string | null
  }>({
    defaultValues: {
      canLogInToPortal: null
    }
  })

  const { watch, handleSubmit } = form
  const formValues = watch()

  const nextInvitation = () => {
    const data = {
      canLogInToPortal: formValues.canLogInToPortal
    }
    doNext?.('next', data)
  }

  const states = [
    'AZ',
    'FL',
    'GA',
    'IL',
    'IN',
    'KS',
    'LA',
    'MI',
    'MO',
    'MS',
    'NC',
    'NE',
    'NH',
    'OH',
    'OK',
    'SC',
    'TN',
    'TX'
  ]

  return (
    <Grid container direction='column' spacing={2}>
      <Grid item>
        <h2 style={{ fontSize: '1.5rem' }}>Eligibility - Not Currently Appointed with UHO</h2>
      </Grid>
      <Grid item>
        <TimeToComplete started={props.dateStarted} timeToComplete={props.timeToComplete} />
      </Grid>
      <Grid item>
        We just pulled the information from the National Insurance Producer Registry.
      </Grid>
      <Grid item>
        <Note>
          <strong>Current Status:</strong> You are currently NOT appointed with UHO.
        </Note>
      </Grid>
      <Grid item>
        You qualify to be appointed for the following{' '}
        <strong>{states.length} states in which UHO operates in:</strong>
      </Grid>
      <Grid item>{states.join(', ')}</Grid>
      <Grid container item justify='flex-end'>
        <Button onClick={handleSubmit(nextInvitation)}>Next: Invitation</Button>
      </Grid>
    </Grid>
  )
}

export default NotAppointed
