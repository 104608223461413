import React, { useEffect, useRef, useState } from 'react'
import { useForm } from 'react-hook-form'

import { Divider, Link } from '@material-ui/core'

import Box from 'components/box'
import Button from 'components/button'
import DateComponent from 'components/date'
import { ErrorsAndOmissionsApiData } from 'store/errors_and_omissions'
import Input, { ChangeEvent } from 'components/input'
import { InvalidIcon, RoundCheckedIcon } from 'components/icons'
import { LightTooltip } from 'components/tooltip'
import { Loading } from 'components/loading'
import Note from 'components/box/note'
import PresignedUrlLink from 'components/presigned_url_link/presigned_url_link'
import { S3_BUCKET_URL } from 'utils/environment-variables'
import { SeeTutorialButton } from 'components/show_tutorial_box'
import Select from 'components/select'
import SuccessBox from 'components/box/success_box'
import Tutorial from 'components/tutorial'
import Warning from 'components/box/warning'
import _ from 'lodash'
import { loader } from 'graphql.macro'
import { parseInputDate } from 'utils/utils'
import styled from 'styled-components'
import { useMutation } from '@apollo/client'
import { useStoreActions, useStoreState } from 'store'

import Partner from './partner'
import ReadOnlyForm from './read_only_form'
import { getSteps } from './tutorial_steps'

const CREATE = loader('./graphql/create_errors_and_omission.gql')
const UPDATE = loader('./graphql/update_errors_and_omission.gql')

const Content = styled.div`
  > * + * {
    margin-left: 24px;
  }
  display: flex;
  align-items: flex-start;
`

const CarrierName = styled.div``

const AccountId = styled.div``

const PolicyNumber = styled.div``

const AggregateCoverageAmount = styled.div``

const PerOccurrenceCoverageAmount = styled.div``

const PolicyStartDate = styled.div``

const PolicyEndDate = styled.div``

const ForYear = styled.div`
  margin-bottom: 24px;
  display: flex;
  > * + * {
    margin-left: 24px;
  }
`

const UploadCertificate = styled.div`
  a {
    margin-left: 10px;
    font-size: 14px;
    font-weight: 600;
  }
  label {
    font-weight: 600;
  }
  > * + * {
    display: block;
    margin-top: 5px;
  }
`

const Form = styled.div`
  @media (max-width: 599px) {
    display: flex;
    flex-direction: column;
    > * + * {
      margin-top: 10px !important;
    }
  }

  @media (min-width: 600px) {
    display: grid;
    gap: 16px 24px;
    grid-template-columns: 1fr 1fr;
    grid-template-areas:
      'startdate enddate'
      'carriername carriername'
      'accountid policynumber'
      'aggcovg percovg'
      'upload upload';

    ${CarrierName} {
      grid-area: carriername;
    }
    ${AccountId} {
      grid-area: accountid;
    }
    ${PolicyNumber} {
      grid-area: policynumber;
    }
    ${AggregateCoverageAmount} {
      grid-area: aggcovg;
    }
    ${PerOccurrenceCoverageAmount} {
      grid-area: percovg;
    }
    ${PolicyStartDate} {
      grid-area: startdate;
    }
    ${PolicyEndDate} {
      grid-area: enddate;
    }
    ${UploadCertificate} {
      grid-area: upload;
    }
  }
`

const Message = styled.span`
  color: #883d07;
  font-size: 12px;
  ${props =>
    props.type === 'success' &&
    `
    color: green;
  `}
  ${props =>
    props.type === 'error' &&
    `
    color: #dd2d30;
  `}
`

const Status = styled.div`
  display: flex;
  align-items: center;
  font-size: 24px;
  > * + * {
    margin-left: 4px;
  }
`

const Main = styled.div`
  > * + * {
    margin-top: 24px;
  }
`

const ErrorsAndOmissions = () => {
  const currentYear = new Date().getUTCFullYear()
  const isDecember = new Date().getUTCMonth() === 11
  const [showTutorial, setShowTutorial] = useState<boolean>(false)
  const [certificatePath, setCertificatePath] = useState('')

  const { register, trigger, reset, watch, errors, setValue, handleSubmit } = useForm<
    Record<string, any>
  >({
    defaultValues: {
      confirm: false,
      fileSelected: undefined,
      year: currentYear,
      certificate: '',
      carrierName: '',
      accountId: '',
      policyNumber: '',
      aggregateCoverageAmount: '',
      perOccurrenceCoverageAmount: '',
      policyStartDate: '',
      policyEndDate: ''
    }
  })

  const storeCreateErrorsAndOmission = useStoreActions(actions => actions.errorsAndOmissions.create)
  const storeUpdateErrorsAndOmission = useStoreActions(actions => actions.errorsAndOmissions.update)

  const [submitMessage, setSubmitMessage] = useState<{
    success: boolean
    message: string
  } | null>(null)

  const addSubmitMessage = (message: { success: boolean; message: string } | null) => {
    setSubmitMessage(message)
  }

  const [createErrorsAndOmission, { loading: creating }] = useMutation(CREATE, {
    onCompleted: data => {
      const errors = data.errorAndOmissionCreate.errorAndOmissionErrors
      const success = errors.length === 0
      if (success) {
        storeCreateErrorsAndOmission(data.errorAndOmissionCreate.errorAndOmission)
      }
      addSubmitMessage({
        success,
        message: success ? 'Creation successful.' : errors[0].message
      })
    },
    onError: error => {
      console.error(error)
      addSubmitMessage({
        success: false,
        message: error.message
      })
    }
  })

  const [updateErrorsAndOmission, { loading: updating }] = useMutation(UPDATE, {
    onCompleted: data => {
      const errors = data.errorAndOmissionUpdate.errorAndOmissionErrors
      const success = errors.length === 0
      if (success) {
        storeUpdateErrorsAndOmission(data.errorAndOmissionUpdate.errorAndOmission)
      }
      addSubmitMessage({
        success,
        message: success ? 'Update successful.' : errors[0].message
      })
    },
    onError: error => {
      console.error(error)
      addSubmitMessage({
        success: false,
        message: error.message
      })
    }
  })

  const errorsAndOmissions = useStoreState(state => state.errorsAndOmissions.items)

  const availableYears = [...errorsAndOmissions.map(row => row.year), currentYear - 1, currentYear]
  if (isDecember) {
    availableYears.push(currentYear + 1)
  }
  const years = _.uniq(availableYears).sort()

  useEffect(() => {
    const FORM_FIELDS = {
      certificate: { required: true },
      fileSelected: { required: false },
      year: { required: true },
      carrierName: { required: true },
      accountId: { required: false },
      policyNumber: { required: true },
      aggregateCoverageAmount: {
        required: true,
        validate: {
          largeEnough: value => {
            return +value >= 1e6
          }
        }
      },
      perOccurrenceCoverageAmount: {
        required: true,
        validate: {
          largeEnough: value => {
            return +value >= 1e6
          }
        }
      },
      policyStartDate: {
        required: true,
        validate: {
          lessThanEndDate: policyStartDate => {
            const policyEndDate = watch('policyEndDate')
            if (!policyEndDate) {
              return true
            }
            return parseInputDate(policyStartDate) <= parseInputDate(policyEndDate)
          },
          samePolicyYear: policyStartDate => {
            return parseInputDate(policyStartDate).getFullYear() === formValues.year
          }
        }
      },
      policyEndDate: {
        required: true,
        validate: {
          notExpiringSoon: policyEndDate => {
            const date = new Date()
            date.setHours(0, 0, 0, 0)
            return parseInputDate(policyEndDate).getTime() >= date.getTime() + 86400 * 1000 * 2
          },
          samePolicyYear: policyEndDate => {
            return parseInputDate(policyEndDate).getFullYear() === formValues.year + 1
          }
        }
      }
    }
    for (const [field, rule] of Object.entries(FORM_FIELDS)) {
      register(field, rule)
    }
  }, [register])

  const formValues = watch()

  const editableForm = formValues.year >= currentYear - 1

  const handleChange = (event: ChangeEvent) => {
    const field = event.name!
    const value = event.value
    setValue(field as any, value)
    if (['aggregateCoverageAmount', 'perOccurrenceCoverageAmount'].includes(field)) {
      trigger(field)
    }
  }

  const fileInput = useRef<HTMLInputElement | null>(null)

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files![0]) {
      setValue('fileSelected', e.target.files![0])
      setValue('certificate', e.target.files![0].name)
    }
  }

  const handleYearChange = (year: number) => {
    const errorsAndOmissionForYear =
      errorsAndOmissions.find(row => row.year === year) || ({} as ErrorsAndOmissionsApiData)

    reset({
      ...formValues,
      year: year,
      carrierName: errorsAndOmissionForYear.carrierName || '',
      certificate: errorsAndOmissionForYear.certificate || '',
      fileSelected: undefined,
      accountId: errorsAndOmissionForYear.accountId || '',
      policyNumber: errorsAndOmissionForYear.policyNumber || '',
      aggregateCoverageAmount: (errorsAndOmissionForYear.coverageAmountInCent || '') + '',
      perOccurrenceCoverageAmount: (errorsAndOmissionForYear.perOccrAmountInCent || '') + '',
      policyStartDate: errorsAndOmissionForYear.startDate || '',
      policyEndDate: errorsAndOmissionForYear.endDate || ''
    })
  }

  useEffect(() => {
    // if the dropdown year changes, reset
    if (fileInput.current) {
      fileInput.current.value = ''
    }
    setValue('fileSelected', undefined)
    setValue('confirm', false)
    setSubmitMessage(null)
  }, [formValues.year])

  useEffect(() => {
    handleYearChange(formValues.year)
  }, [errorsAndOmissions])

  useEffect(() => {
    setCertificatePath(formValues.certificate)
  }, [formValues.certificate])

  const submit = formValues => {
    addSubmitMessage(null)
    const input = {
      carrierName: formValues.carrierName,
      accountId: formValues.accountId,
      policyNumber: formValues.policyNumber,
      coverageAmountInCent: +formValues.aggregateCoverageAmount,
      perOccrAmountInCent: +formValues.perOccurrenceCoverageAmount,
      year: formValues.year,
      certificate: formValues.fileSelected,
      startDate: formValues.policyStartDate,
      endDate: formValues.policyEndDate
    }

    const existingErrorAndOmission = errorsAndOmissions.find(info => info.year === formValues.year)
    if (existingErrorAndOmission == null) {
      createErrorsAndOmission({
        variables: {
          input
        }
      })
    } else {
      updateErrorsAndOmission({
        variables: {
          input,
          id: existingErrorAndOmission.id
        }
      })
    }
  }

  const selectedYearFormFilled =
    errorsAndOmissions.find(row => row.year === formValues.year) != null

  const expiringSoon =
    selectedYearFormFilled &&
    parseInputDate(errorsAndOmissions.find(d => d.year === formValues.year)!.endDate).getTime() -
      new Date().getTime() <=
      86400 * 1000 * 60 // within 60 days of expiry

  return (
    <Main id='errors_and_omissions_body'>
      <div id='errors_and_omissions_message'>
        {!selectedYearFormFilled && (
          <Warning>
            You do not have valid Errors & Omissions insurance for {formValues.year}–
            {formValues.year + 1}. You must have a valid Errors & Omissions insurance policy to sell
            on on the Assurance platform.
          </Warning>
        )}
        {selectedYearFormFilled && !expiringSoon && (
          <SuccessBox>
            Good job! Your Errors & Omissions insurance is approved for {formValues.year}–
            {formValues.year + 1}.
          </SuccessBox>
        )}
        {selectedYearFormFilled && expiringSoon && (
          <Note>
            <strong>Your Errors & Omissions policy is expiring soon!</strong> Update your Errors &
            Omissions information to ensure you’ll still be able to access Delta at the turn of{' '}
            {formValues.year + 2}.
          </Note>
        )}
      </div>
      <Content>
        <div style={{ flexBasis: '50%' }}>
          <ForYear>
            <div id='errors_and_omissions_status'>
              <span style={{ fontWeight: 600 }}>Errors & Omissions Policy Year</span>
              <Select
                style={{ minWidth: '200px', marginTop: '8px', display: 'block' }}
                selected={formValues.year}
                options={years.map(year => ({ year: year }))}
                keySelector={option => option.year}
                labelSelector={option => option.year + '–' + (option.year + 1)}
                titleSelector={option => {
                  if (option) {
                    return option.year + '–' + (option.year + 1)
                  }
                  return '–'
                }}
                onChange={handleYearChange}
              />
            </div>
            <Divider orientation='vertical' flexItem />
            <div>
              <span style={{ fontWeight: 600 }}>Errors & Omissions Status</span>
              <Status style={{ marginTop: '8px' }}>
                {selectedYearFormFilled ? <RoundCheckedIcon /> : <InvalidIcon />}
                <span style={{ color: selectedYearFormFilled ? '#008846' : '#DD2D30' }}>
                  {selectedYearFormFilled ? 'Complete' : 'Incomplete'}
                </span>
              </Status>
            </div>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <SeeTutorialButton onClick={() => setShowTutorial(true)} />
              {(updating || creating) && (
                <Box marginLeft='1rem'>
                  <Loading />
                </Box>
              )}
            </div>
          </ForYear>
          {!selectedYearFormFilled && (
            <div style={{ marginBottom: '1rem' }}>
              You’ll need to have a copy of your E&O liability insurance certificate available in
              order to fill out the fields below. You can refer to this sample{' '}
              <Link target='_blank' rel='noreferrer' href='/images/e_o_sample_certificate.jpg'>
                certificate
              </Link>{' '}
              for assistance.
            </div>
          )}
          {!editableForm && (
            <ReadOnlyForm
              {...(errorsAndOmissions.find(row => row.year === formValues.year) as any)}
            />
          )}
          {editableForm && (
            <>
              <Form id='errors_and_omissions_form'>
                <PolicyStartDate>
                  <DateComponent
                    label={
                      <Box display='flex'>
                        <span>Policy Start Date*</span>
                        <LightTooltip
                          placement='top-start'
                          title={
                            <div>
                              <strong>Policy Start Date</strong>
                              <Box marginTop='0.5rem'>
                                Policy Start Date is the date which your E&O policy starts. The date
                                entered in this field should match the drop down Errors & Omissions
                                Policy Year that you selected above.
                              </Box>
                            </div>
                          }
                        >
                          <img
                            style={{ marginLeft: '0.25rem', width: '1.25rem' }}
                            src='/images/info.svg'
                          />
                        </LightTooltip>
                      </Box>
                    }
                    value={formValues.policyStartDate}
                    onChange={value => {
                      handleChange({
                        name: 'policyStartDate',
                        value
                      })
                    }}
                  />
                  {errors.policyStartDate && (
                    <Message type={'error'}>
                      {errors.policyStartDate?.type === 'required' && 'This field is required'}
                      {errors.policyStartDate?.type === 'lessThanEndDate' &&
                        'This field must be less than or equal to the end date'}
                      {errors.policyStartDate?.type === 'samePolicyYear' &&
                        'The date entered in this field should match the Policy Year.'}
                    </Message>
                  )}
                </PolicyStartDate>
                <PolicyEndDate>
                  <DateComponent
                    label={
                      <Box display='flex'>
                        <span>Policy End Date*</span>
                        <LightTooltip
                          placement='top-start'
                          title={
                            <div>
                              <strong>Policy End Date</strong>
                              <Box marginTop='0.5rem'>
                                Policy End Date is the date which your E&O policy ends. The date
                                entered in this field should match the drop down Policy Year that
                                you selected above. The end date should not match today’s current
                                date.
                              </Box>
                            </div>
                          }
                        >
                          <img
                            style={{ marginLeft: '0.25rem', width: '1.25rem' }}
                            src='/images/info.svg'
                          />
                        </LightTooltip>
                      </Box>
                    }
                    value={formValues.policyEndDate}
                    onChange={value => {
                      handleChange({
                        name: 'policyEndDate',
                        value
                      })
                    }}
                  />
                  {errors.policyEndDate && (
                    <Message type={'error'}>
                      {errors.policyEndDate?.type === 'required' && 'This field is required'}
                      {errors.policyEndDate?.type === 'notExpiringSoon' &&
                        'This policy should not be expiring in few days'}
                      {errors.policyEndDate?.type === 'samePolicyYear' &&
                        'The date entered in this field should match the Policy Year.'}
                    </Message>
                  )}
                </PolicyEndDate>
                <CarrierName>
                  <Input
                    label='Carrier Name*'
                    name='carrierName'
                    value={formValues.carrierName}
                    onChange={handleChange}
                  />
                  {errors.carrierName && <Message type={'error'}>This field is required</Message>}
                </CarrierName>
                <AccountId>
                  <Input
                    label={
                      <Box display='flex' alignItems='baseline'>
                        <span>Account ID</span>
                        <LightTooltip
                          placement='top-start'
                          title={
                            <div>
                              <strong>Account ID</strong>
                              <Box marginTop='0.5rem'>
                                Account ID will not be found on your E&O certificate. An account ID
                                is supplied by your insurance carrier and should be an identifier
                                that is unique to you. If you do not have an Account ID, leave this
                                field blank.
                              </Box>
                            </div>
                          }
                        >
                          <div style={{ marginLeft: 'auto' }}>
                            <Box
                              fontSize='0.75rem'
                              color='primary.main'
                              display='flex'
                              alignItems='center'
                            >
                              Can't find your Account ID?
                              <img
                                style={{ marginLeft: '0.25rem', width: '1.25rem' }}
                                src='/images/info_blue.svg'
                              />
                            </Box>
                          </div>
                        </LightTooltip>
                      </Box>
                    }
                    name='accountId'
                    value={formValues.accountId}
                    onChange={handleChange}
                  />
                  {errors.accountId && <Message type={'error'}>This field is required</Message>}
                </AccountId>
                <PolicyNumber>
                  <Input
                    label='Policy Number*'
                    name='policyNumber'
                    value={formValues.policyNumber}
                    onChange={handleChange}
                  />
                  {errors.policyNumber && <Message type={'error'}>This field is required</Message>}
                </PolicyNumber>
                <AggregateCoverageAmount>
                  <Input
                    label={
                      <Box display='flex'>
                        Aggregate Coverage Amount*
                        <LightTooltip
                          placement='top-start'
                          title={
                            <div>
                              Also known as...
                              <ul
                                style={{
                                  marginTop: '.5rem',
                                  paddingInlineStart: '1.5rem'
                                }}
                              >
                                <li>Annual Coverage Amount</li>
                              </ul>
                            </div>
                          }
                        >
                          <img
                            style={{ marginLeft: '0.25rem', width: '1.25rem' }}
                            src='/images/info.svg'
                          />
                        </LightTooltip>
                      </Box>
                    }
                    mask='$'
                    type='number'
                    formatNumber
                    name='aggregateCoverageAmount'
                    value={formValues.aggregateCoverageAmount}
                    onChange={handleChange}
                  />
                  <Message
                    type={
                      errors.aggregateCoverageAmount
                        ? 'error'
                        : formValues.aggregateCoverageAmount
                        ? 'success'
                        : void 0
                    }
                  >
                    {errors.aggregateCoverageAmount?.type === 'required' && (
                      <span>
                        This field is required
                        <br />
                      </span>
                    )}
                    ≥ $1,000,000 Aggregate Coverage Amount
                  </Message>
                </AggregateCoverageAmount>
                <PerOccurrenceCoverageAmount>
                  <Input
                    label={
                      <Box display='flex'>
                        Per Occurrence Coverage Amount*
                        <LightTooltip
                          placement='top-start'
                          title={
                            <div>
                              Also known as...
                              <ul
                                style={{
                                  marginTop: '.5rem',
                                  paddingInlineStart: '1.5rem'
                                }}
                              >
                                <li>Per Claim</li>
                                <li>Each Claim</li>
                              </ul>
                            </div>
                          }
                        >
                          <img
                            style={{ marginLeft: '0.25rem', width: '1.25rem' }}
                            src='/images/info.svg'
                          />
                        </LightTooltip>
                      </Box>
                    }
                    mask='$'
                    type='number'
                    formatNumber
                    name='perOccurrenceCoverageAmount'
                    value={formValues.perOccurrenceCoverageAmount}
                    onChange={handleChange}
                  />
                  <Message
                    type={
                      errors.perOccurrenceCoverageAmount
                        ? 'error'
                        : formValues.perOccurrenceCoverageAmount
                        ? 'success'
                        : void 0
                    }
                  >
                    {errors.perOccurrenceCoverageAmount?.type === 'required' && (
                      <span>
                        This field is required
                        <br />
                      </span>
                    )}
                    ≥ $1,000,000 Per Occurrence Coverage Amount
                  </Message>
                </PerOccurrenceCoverageAmount>
                <UploadCertificate>
                  <label>Upload your Errors & Omissions Certificate*</label>
                  <input
                    ref={fileInput}
                    type='file'
                    accept='.jpg,.png,.pdf,.jpeg'
                    onChange={handleFileChange}
                    style={{ display: 'none' }}
                  />
                  <span>JPG, PNG, PDF allowed</span>
                  <div>
                    <Button
                      color='secondary'
                      onClick={() => {
                        fileInput.current!.click()
                      }}
                    >
                      Select File
                    </Button>
                    {formValues.fileSelected ? (
                      <span style={{ marginLeft: '10px', fontWeight: 600 }}>
                        {formValues.fileSelected.name}
                      </span>
                    ) : certificatePath === '' ? (
                      ''
                    ) : (
                      <PresignedUrlLink
                        url={
                          S3_BUCKET_URL.replace('https://', '').split('.')[0] +
                          '/' +
                          certificatePath
                        }
                      />
                    )}
                    <br />
                    {errors.certificate && <Message type={'error'}>Please upload a file.</Message>}
                  </div>
                </UploadCertificate>
              </Form>
              {submitMessage && (
                <Message
                  type={submitMessage.success ? 'success' : 'error'}
                  style={{ display: 'block', marginTop: '20px', fontSize: '1.2rem' }}
                >
                  {submitMessage.message}
                </Message>
              )}
            </>
          )}
        </div>
        <Partner id='errors_and_omissions_partner' />
      </Content>
      <Divider />
      {editableForm && (
        <div id='errors_and_omissions_submit'>
          <label>
            <input name='confirm' type='checkbox' ref={register({ required: true })} /> I attest
            that the information above is correct, and I understand that carrying Errors and
            Omissions (E&O) insurance is a requirement as stated in the Master Service Agreement
            (MSA) I have executed with Assurance IQ.
          </label>
          <Button style={{ marginLeft: '50px', width: '200px' }} onClick={handleSubmit(submit)}>
            Submit
          </Button>
          {Object.keys(errors).length === 1 && errors.confirm && (
            <Message type='error' style={{ display: 'block' }}>
              Please confirm that you have entered correct information.
            </Message>
          )}
        </div>
      )}
      {showTutorial && (
        <Tutorial tutorialSteps={getSteps()} onFinish={() => setShowTutorial(false)} />
      )}
    </Main>
  )
}

export default ErrorsAndOmissions
