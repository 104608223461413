import React, { Dispatch, SetStateAction, useState } from 'react'

import { Grid } from '@material-ui/core'

import { AccountProps } from './create_link_account.d'
import AhipPromoBanner from './ahip_promo_banner'

const NoAhipAccountFlow = (props: AccountProps) => {
  const { page_data, allowContinue, setAllowContinue } = props
  const [checked, setChecked] = useState(false)
  const handleChange = () => {
    setChecked(!checked)
    setAllowContinue(!allowContinue)
  }

  return (
    <>
      <Grid item>
        <strong>Step 1:</strong> Click our promotional link to navigate to the AHIP website to sign
        up with a discount:
      </Grid>
      <Grid
        style={{ gap: '26.4px', width: '684px', border: '1px solid lightgrey' }}
        item
        container
        direction='row'
      >
        <AhipPromoBanner
          ahipLink={(page_data as { ahip_acc_link: string })?.ahip_acc_link || '#'}
        />
      </Grid>
      <Grid item>
        <strong>Step 2:</strong> Create an AHIP Account. Please see this{' '}
        <a href='/docs/AHIP_Online_Medicare_Training_User_Guide.pdf' target='_blank'>
          step-by-step guide for detailed instructions
        </a>
        .
      </Grid>
      <Grid item>
        <img
          style={{ width: '685.27px' }}
          alt='ahip-certification-no-account-step-1'
          src='/images/certification/ahip/ahip-create_link_account-no_user_info-no_account_1.png'
        />
      </Grid>
      <Grid item>
        <strong>Step 3:</strong> Link your data to Assurance by clicking <strong>Continue</strong>.
      </Grid>
      <Grid item>
        <img
          style={{ width: '685.27px' }}
          alt='ahip-certification-no-account-step-2'
          src='/images/certification/ahip/ahip-create_link_account-no_user_info-no_account_2.png'
        />
      </Grid>
      <Grid item>
        <input type='checkbox' checked={checked} onChange={() => handleChange()} />
        <span> I logged into my AHIP account and transmitted my data</span>
      </Grid>
    </>
  )
}

export default NoAhipAccountFlow
